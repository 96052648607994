<template>
  <div
    class="projects-card"
    @click.stop="openProject">
    <div class="d-flex flex-column gap-1">
      <span class="blue--text fs-10 text-uppercase">{{ item.phase }}</span>

      <!-- name and number -->
      <template>
        <div
          v-if="!isRenaming"
          class="font-fam-poppins">
          <span
            v-if="item.number"
            class="me-2 darkGrey--text fs-16 font-weight-600 text-capitalize">{{ item.number }}</span>
          <span class="black--text fs-18 lh-normal text-capitalize">{{ item.projectName }}</span>
        </div>
        <!-- input for rename -->
        <div v-else>
          <v-text-field
            ref="editInput"
            v-model="editName"
            autocomplete="off"
            class="edit-input"
            placeholder="Enter project name"
            @blur="saveEditedName"
            @keyup.enter="saveEditedName"
            @click.stop />
        </div>
      </template>

      <span class="darkGrey--text fs-11 text-uppercase">
        Last modified {{ $formatProjectDates(item.projectLastModified) }}
      </span>

      <!-- tags -->
      <div class="d-flex flex-wrap gap-2 py-2">
        <v-chip
          v-for="tag in item.tags"
          :key="tag"
          color="black"
          class="fs-10 text-uppercase"
          text-color="white">
          {{ tag }}
        </v-chip>

        <EditTagsContainer
          v-if="showEditTagsContainer"
          :project-tags="item.tags"
          :project-id="item.id"
          @changeProjectTags="changeProjectTags" />
      </div>
    </div>

    <!-- creators and members -->
    <div class="d-flex justify-space-between gap-2 relative">
      <div class="d-flex gap-1 projects-card__creator-wrapper">
        <AvatarImage
          error-action="getUserAvatar"
          :add-additional-size-to-icon="false"
          :avatar-key="userAvatarKey"
          :avatar="userAvatar"
          :size="30" />

        <div class="d-flex flex-column justify-center gap-1 projects-card__creator">
          <span class="fs-12 font-fam-poppins text-capitalize text-truncate">{{ item.creator.name }}</span>
          <span class="darkGrey--text fs-10 text-uppercase">creator</span>
        </div>
      </div>

      <AppMembers
        v-if="item.memberSummary"
        :members="item.memberSummary.users"
        :member-count="item.memberSummary.count"
        :role="item.type"
        @showShareModalAction="showShareModalAction(item)" />
    </div>

    <!-- settings icon -->
    <v-icon
      class="projects-card__settings"
      color="black"
      size="18"
      @click.stop="setMenuItem({
        item,
        event: $event,
      })">
      mdi-settings
    </v-icon>

    <!-- right now the remane is in place of the actual name -->
    <TableActions
      :show-activator="false"
      :item.sync="selectedItem"
      :position-x="positionX"
      :position-y="positionY"
      :value.sync="showActionsCard"
      @change-status="changeStatus"
      @copy-project="copy"
      @delete-project="deleteProject"
      @rename-project="rename" />
  </div>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex';

import {
  ACTIVE, ARCHIVED,
} from '@/constants';

import ProjectsApi from '@/services/graphql/projects';

import AppMembers from '@/components/App/AppTable/AppTableMembers';
import EditTagsContainer from '@/components/Projects/ProjectsTable/ProjectsTableStructure/EditTagsContainer';
import TableActions from '@/components/Projects/ProjectsTable/ProjectsTableStructure/TableActions';

import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';

export default {
  name: 'ProjectsCard',
  components: {
    AppMembers,
    EditTagsContainer,
    TableActions,
  },
  mixins: [AppActionDotsMenu],
  props: {
    item: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      editName: '',
      projectId: null,
      projectTags: [],
      isRenaming: false,
    };
  },
  computed: {
    ...mapState('Projects', ['groupByTags']),
    ...mapState('UserProfile', ['userAvatar', 'userAvatarKey']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    isArchivedProjects() {
      return this.item.status === ARCHIVED;
    },
    showEditTagsContainer() {
      if (this.isArchivedProjects) return false;
      return !this.groupByTags[ACTIVE]?.groupByTagsTrigger;
    },
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
      setCreatedProject: 'Projects/setCreatedProject',
    }),
    ...mapMutations({
      showShareModalAction: 'showShareModalAction',
      spinner: 'spinner',
    }),
    changeProjectTags(tags) {
      this.$emit('updateProject', {
        ...this.item,
        tags,
      });
    },
    changeStatus(item) {
      const jsonForRequest = {
        id: item.id,
        projectName: item.projectName,
        status: item.status === ARCHIVED ? ACTIVE : ARCHIVED,
        sourceLibraryId: item.sourceLibraryId,
      };
      this.updateProject(jsonForRequest, 'status');
    },
    async copy(item) {
      this.spinner(true);
      try {
        const { data } = await ProjectsApi.copyProject({
          projectId: item.id,
          workspaceId: this.activeWorkspaceId,
        });
        this.setCreatedProject(data.response);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async deleteProject(item) {
      const response = await this.$openConfirm({
        text: `Are you sure you want to delete project ${item.projectName}?`,
      });
      if (response) {
        this.spinner(true);

        try {
          await ProjectsApi.deleteProject({
            id: item.id,
            workspaceId: this.activeWorkspaceId,
          });
          this.$emit('deleteProject', item.id);
        } catch (err) {
          this.handleError(err);
        } finally {
          this.spinner(false);
        }
      }
    },
    editTags({ tags, id }) {
      this.projectTags = tags || [];
      this.projectId = id;
    },
    openProject() {
      if (this.isRenaming) return;

      this.$router.push({
        path: `project/${this.item.id}`,
      });
    },
    rename(item) {
      this.isRenaming = true;
      this.editName = item.projectName;
      this.$nextTick(() => {
        this.$refs.editInput.focus();
      });
    },
    saveEditedName() {
      if (!this.editName) {
        this.isRenaming = false;
      } else {
        const { id, projectName, status } = this.item;

        if (projectName === this.editName) {
          this.isRenaming = false;
          return;
        }

        const jsonForRequest = {
          id,
          projectName: this.editName,
          status,
        };
        this.updateProject(jsonForRequest, 'name');
      }
    },
    async updateProject(data, criteria) {
      this.spinner(true);
      try {
        const currentItem = this.item;

        const response = await ProjectsApi.updateProject({
          ...data,
          workspaceId: this.activeWorkspaceId,
        });
        const updatedProject = response.data.response;

        if (criteria === 'status') {
          this.$emit('updateStatus', {
            ...currentItem,
            status: updatedProject.status,
          });
        } else {
          this.isRenaming = false;
          this.$emit('updateProject', {
            ...currentItem,
            projectName: updatedProject.projectName,
          });
        }
      } catch (err) {
        this.isRenaming = false;
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.projects-card {
  position: relative;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  padding: 30px 24px 20px;
  border-radius: 10px;
  background: var(--v-lightGrey-base);
  cursor: pointer;

  &__creator-wrapper {
    max-width: calc(100% - 174px);
  }

  &__creator {
    width: 100%;
  }

  &__settings {
    position: absolute;
    height: 18px;
    right: 8px;
    top: 8px;
    width: 18px;
  }
}
</style>